.ant-steps-item-process .ant-steps-item-icon {
    border-color: #7a9e5e;
    background: #7a9e5e;
}
.ant-steps-item-finish .ant-steps-item-icon {
	border-color: #7a9e5e;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: #7a9e5e;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #7a9e5e;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #7a9e5e;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: #7a9e5e;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: #7a9e5e;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
	background-color: #7a9e5e;
}
.ant-descriptions-view {
	border-radius: 10px;
}
.ant-descriptions-bordered .ant-descriptions-view {
	border: none;
}
.ant-steps-navigation .ant-steps-item::before{
    background-color: #7a9e5e;
}
.ant-descriptions-item-label {
	color: #ffffff;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5715;
	text-align: start;
}
.ant-steps-item-title {
	font-size: 18px;
}
.ant-descriptions {
	width: 90%;
	margin: 0 auto;
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
	margin-bottom: 50px;
	background-color: #ffffff;
}
.detail-progress {
	width: 90% !important;
	margin: 0 auto !important;
	margin-bottom: 60px;
	box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}
.detail-user {
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	margin-left: 5px;
}
.detail-info {
	width: 90%;
	margin: 0 auto;
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
	margin-bottom: 50px;
    background-color: #ffffff;
    padding: 2em 3em;
}
@media (min-width: 1445px) {
	.detail-progress {
		width: 60% !important;
	}
	.ant-descriptions {
		width: 60%;
    }
    .detail-info {
		width: 60%;
	}
}
