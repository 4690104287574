@import url(https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* General */
body {
	margin: 0;
	font-family: 'Muli', sans-serif !important;
}

code {
	font-family: 'Alegreya Sans', sans-serif;
}

/*  Form  */
.form-container {
	width: 90% !important;
	margin: 0 auto !important;
	margin-bottom: 35px !important;
}

.input-form {
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
	border-radius: 8px !important;
	padding-top: 16px !important;
	padding-bottom: 16px !important;
	color: black !important;
	border: 1px solid rgb(233, 233, 233) !important;
	font-size: 1.1em !important;
	background-color: white !important;
	width: 100% !important;
}

.input-form .ant-select-selector {
	border: none !important;
	cursor: pointer !important;
}

.input-form .ant-select-selection-placeholder {
	opacity: .60 !important;
}



@media (min-width: 1445px) {
	.form-container {
		width: 40% !important;
	}
}

/*         */
.breadcrumb-page {
	width: 90% !important;
	margin: 0 auto !important;
	margin-bottom: 35px !important;
}

.button-back {
	width: 46px !important;
	height: 46px !important;
	padding: 4.9px 0 !important;
	font-size: 18px !important;
	background-color: #469730 !important;
	border-color: #469730 !important;
	margin-right: 20px;
}

.button-back:hover {
	background-color: #5aa546 !important;
	border-color: #5aa546 !important;
}

.button-submit {
	width: 100% !important;
	height: 68px !important;
	padding: 8.9px 0 !important;
	font-size: 18px !important;
	background-color: #469730 !important;
	border-color: #469730 !important;
	margin-right: 20px;
	border-radius: 8px !important;
	margin: 0 auto !important;
	margin-top: 15px !important;
	margin-bottom: 25px !important;
}

.button-submit:hover {
	background-color: #5aa546 !important;
	border-color: #5aa546 !important;
}

.button-submit-red {
	width: 100% !important;
	height: 68px !important;
	padding: 8.9px 0 !important;
	font-size: 18px !important;
	background-color: #ea5455 !important;
	border-color: #ea5455 !important;
	margin-right: 20px;
	border-radius: 8px !important;
	margin: 0 auto !important;
	margin-top: 15px !important;
	margin-bottom: 25px !important;
}

.button-submit-red:hover {
	background-color: #f7595a !important;
	border-color: #f7595a !important;
}

.button-submit-blue {
	width: 100% !important;
	height: 68px !important;
	padding: 8.9px 0 !important;
	font-size: 18px !important;
	background-color: #00cfe8 !important;
	border-color: #00cfe8 !important;
	margin-right: 20px;
	border-radius: 8px !important;
	margin: 0 auto !important;
	margin-top: 15px !important;
	margin-bottom: 25px !important;
}

.button-submit-blue:hover {
	background-color: #0cdef5 !important;
	border-color: #0cdef5 !important;
}

.button-submit-purple {
	width: 100% !important;
	height: 68px !important;
	padding: 8.9px 0 !important;
	font-size: 18px !important;
	background-color: #7367f0 !important;
	border-color: #7367f0 !important;
	margin-right: 20px;
	border-radius: 8px !important;
	margin: 0 auto !important;
	margin-top: 15px !important;
	margin-bottom: 25px !important;
}

.alert-info {
	width: 100% !important;
	margin: 0 auto !important;
}

.button-submit-purple:hover {
	background-color: #8074ff !important;
	border-color: #8074ff !important;
}

@media (min-width: 1445px) {
	.breadcrumb-page {
		width: 60% !important;
	}
}

.ant-table {
	overflow-x: auto !important;
}

.ant-table-thead > tr > th {
	min-width: 200px !important;
}

.form-input-login{
    margin-top: 35px;
    width: 100%;
    padding: 0px 20px;
}

.login-form-button{
    background-color: #469730;
    border-color: #469730;
}

.login-form-button:hover{
    background-color: #5aa546;
    border-color: #5aa546;
}

.form-input-login .ant-input-affix-wrapper{
    padding: 8px 11px;
    border-radius: 6px !important;
    font-size: 18px;
}

.form-input-login .ant-input-prefix{
    color: #469730;
}
#login{
    width: 100%;
    margin: 0 auto;
    background-color: #cae9b4;
}

.login-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: url(/static/media/bg-login4.7a0d184e.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.wrap-login{
    width: 100%;
    height: 550px;
    background: #e7f5dc;
    margin: 0 auto;
    text-align: center;
    clip-path: polygon(0% 0%, 100% 100px, 100% 100%, 0% calc(100% - 100px));
    -webkit-clip-path: polygon(0% 0%, 100% 100px, 100% 100%, 0% calc(100% - 100px));
    -o-clip-path: polygon(0% 0%, 100% 100px, 100% 100%, 0% calc(100% - 100px));
    -moz-clip-path: polygon(0% 0%, 100% 100px, 100% 100%, 0% calc(100% - 100px));
}

.form-login{
    margin-top: 80px;
}

.form-login h2{
    font-size: 1.7em !important;
    margin-top: 20px;
}

.form-login img{
    width: 100px;
    height: auto;
}

.background-login{
    height: 1200px;
  width: 200px;
  overflow: hidden;
  transform:  rotate(25deg);
   position: absolute;
   top: -200px;
}
.add-control{
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fdfcfc;
    padding-top: 100px;
    min-height: 100vh;
}

.location-wrap{
    width: 90% !important;
    padding-top: 100px;
    margin: 0 auto;
    text-align: center;
}

.location-wrap h2{
    margin-top: 25px;
}


@media (min-width: 1445px) {
    .location-wrap{
        width: 60% !important;
    }
}
.add-control-containter{
    margin-bottom: 16px;
}
.site-layout-content {
	background: #fff;
	padding: 24px;
	min-height: 280px;
}

#components-layout-demo-top .logo {
	width: 120px;
	height: 31px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px 24px 16px 0;
	float: left;
}

.header-mobile {
	width: 100%;
	background-image: linear-gradient(135deg, #cae9b4 0%, #469730 100%);
	height: 130px;
}

.subheader-mobile {
	display: flex;
	background: white;
	width: 90%;
	min-height: 80px;
	border-radius: 15px;
	margin: 0 auto;
	position: relative;
	z-index: 12;
	top: 40px;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: .5rem;
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
}

.title-mobile {
	color: white;
	text-align: center;
	padding-top: 10px;
	font-size: 20px;
	font-weight: 400;
}

.icon-header-mobile {
	width: 18px;
	height: 18px;
	vertical-align: middle;
}

.subheader-mobile h3 {
	color: #424242;
	font-weight: 700;
	margin: 0px;
}
.subheader-mobile h4 {
	color: #707070;
	font-weight: 600;
	margin: 0px;
}

.subheader-mobile svg {
	margin-top: 3px;
	margin-right: 8px;
}

.icon-content-mobile {
	display: flex;
	padding: .3rem 0rem .3rem 0rem;
}

.icon-content-mobile:first-of-type {
	padding: 1.2rem 0rem .3rem 0rem;
}

.icon-menu-mobile {
	color: white;
	vertical-align: bottom;
	width: auto;
	height: 26px;
	margin-top: 14px;
	margin-left: 5px;
	float: right;
	cursor: pointer;
}

.icon-plus-mobile {
	color: white;
	vertical-align: bottom;
	width: auto;
	height: 26px;
	margin-top: 14px;
	margin-right: 5px;
	float: left;
}

@media (min-width: 1445px) {
	.subheader-mobile {
		width: 60%;
	}
}

.sidebar {
	position: fixed;
	width: 220px;
	margin-left: -220px;
	height: 100vh;
	background: #95bd77;
	font-size: 0.65em;
	z-index: 999;
	top: 0;
	left: 0;
	transition: .3s ease all;
}

.nav {
	position: relative;
	margin: 0 15% 0 5%;
	text-align: right;
	top: 25%;
	transform: translateY(-50%);
	font-weight: bold;
}

.nav ul {
	list-style: none;
}
.nav a {
	line-height: 5em;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 0.4em;
	color: white !important;
	display: block;
	transition: all ease-out 300ms;
}
.nav li {
	position: relative;
	margin: 2.2em 0;
}

.nav .closebtn {
	font-size: 20px;
}
.nav li:first-of-type::after {
	width: 0% !important;
}

.showSidenav {
	margin-left: 0px;
}

.ant-avatar-group .ant-avatar{
    border: 1px solid #fff;
}

.ant-avatar-group .ant-avatar:not(:first-child){
    margin-left: -6px;
}

.ant-table-pagination.ant-pagination{
    margin: 16px 16px;
}
.card-info-container{
    width: 90%;
    min-height: 110px;
    background-color: white;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 10px 15px;
    transition: .3s ease all;
    cursor: pointer;
}

.open{
    border-left: 5px solid #28C76F;
}

.closed{
    border-left: 5px solid #EA5455;
}

.wait{
    border-left: 5px solid #faad14;
}

.card-info-time{
    color: #727272;
    font-size: 13px;
    font-weight: 600;
    display: inline;
    margin: 0px;
}

.card-info-status{
    float: right;
    font-weight: 600;
    color: #5c5c5c;
}

.card-info-status .ant-badge-status-text{
    color: #5c5c5c;
    font-size: 13px;
    font-weight: 600;
}

.card-info-status .ant-badge-status-success{
    background-color: #28C76F !important;
}

.card-info-status .ant-badge-status-danger{
    background-color: #EA5455 !important;
}

.card-info-footer{
    color: #202020;
    font-size: 12px;
    font-weight: 600;
    display: inline;
    margin: 0px;
}

.card-info-footer span{
    color: #5c5c5c;
    font-weight: 500;
    font-style: italic;
}

.card-info-actions{
    min-height: 110px;
    background-color: blue;
    transition: .3s ease all;
}

@media (min-width: 1445px) {
    .card-info-container{
        width: 60%;
    }
}

.card-list-caracterizaciones{
    width: 100%;
    background-color: #fdfcfc;
    padding-top: 100px;
    min-height: 100vh;
}

.user-table-content{
    width: 100%;
    background-color: #fdfcfc;
    padding-top: 100px;
    min-height: 100vh;
}

.table-users{
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
    margin-bottom: 50px;
}

@media (min-width: 1445px) {
    .table-users{
        width: 60%;
    }
}

.ant-table-thead > tr > th {
    color: white;
    font-weight: 500;
    text-align: center;
    background: #95bd77;
    border-bottom: 1px solid #7a9e5e;
    transition: background 0.3s ease;
    
}

.ant-table-thead th.ant-table-column-has-sorters {
    color: white;
    font-weight: 500;
    text-align: center;
    background: #95bd77;
    border-bottom: 1px solid #7a9e5e;
    transition: background 0.3s ease;
}

.ant-table-thead th.ant-table-column-has-sorters::selection {
    color: black;
    font-weight: 500;
    text-align: center;
    background: #cae9b4;
    border-bottom: 1px solid #7a9e5e;
    transition: background 0.3s ease;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    color: white;
    font-weight: 500;
    text-align: center;
    background: #cae9b4;
    border-bottom: 1px solid #7a9e5e;
    transition: background 0.3s ease;
}

.ant-table-column-sorter {
    color: white;
}

.ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    text-align: center;
}

.ant-table-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    position: relative;
    z-index: 0;
    clear: both;
    font-size: 14px;
    background: #fff;
    border-radius: 10px;
}

.ant-table table {
    width: 100%;
    text-align: left;
    border-radius: 10px 10px 0 0;
    border-collapse: separate;
    border-spacing: 0;
}

.ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.ant-table-thead th.ant-table-column-sort {
    background: #cae9b4;
}

td.ant-table-column-sort {
    background: #edffe0;
}

.success-button{
    color: #fff;
    background: #95bd77;
    border-color: #95bd77;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.success-button:hover{
    color: #fff;
    background: #95bd77;
    border-color: #95bd77;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.breadcrumb-page input{
    border-radius: 10px;
    padding: .5em 1em;
    font-size: 16px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    width: 100%;
    display: block;
}

.search button{
    margin-top: 25px;
}

.search label{
    margin-left: 5px;
    font-weight: 600;
}
.addUser{
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fdfcfc;
    padding-top: 100px;
    min-height: 100vh;
}



add-control{
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fdfcfc;
    padding-top: 100px;
    min-height: 100vh;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #7a9e5e;
    background: #7a9e5e;
}
.ant-steps-item-finish .ant-steps-item-icon {
	border-color: #7a9e5e;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: #7a9e5e;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #7a9e5e;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #7a9e5e;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: #7a9e5e;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
	background: #7a9e5e;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
	background-color: #7a9e5e;
}
.ant-descriptions-view {
	border-radius: 10px;
}
.ant-descriptions-bordered .ant-descriptions-view {
	border: none;
}
.ant-steps-navigation .ant-steps-item::before{
    background-color: #7a9e5e;
}
.ant-descriptions-item-label {
	color: #ffffff;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5715;
	text-align: start;
}
.ant-steps-item-title {
	font-size: 18px;
}
.ant-descriptions {
	width: 90%;
	margin: 0 auto;
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
	margin-bottom: 50px;
	background-color: #ffffff;
}
.detail-progress {
	width: 90% !important;
	margin: 0 auto !important;
	margin-bottom: 60px;
	box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}
.detail-user {
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
	margin-left: 5px;
}
.detail-info {
	width: 90%;
	margin: 0 auto;
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
	margin-bottom: 50px;
    background-color: #ffffff;
    padding: 2em 3em;
}
@media (min-width: 1445px) {
	.detail-progress {
		width: 60% !important;
	}
	.ant-descriptions {
		width: 60%;
    }
    .detail-info {
		width: 60%;
	}
}

